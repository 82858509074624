import React from "react"
import './components.css'
import { useState, useRef } from 'react';

function SurveyUrls() {
    const timer = useRef(null)
    const [handle, setHandle] = useState("")
    const [passcode, setPasscode] = useState("")
    const [notFound, setNotFound] = useState(false)
    const [surveyList, setSurveyList] = useState({}); 

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSurveyList({})
        if (!handle) { return }
        clearTimeout(timer.current); 
        setNotFound(false)
        const response = await fetch(`/getSurveyUrls?handle=${handle}&passcode=${passcode}`, {})
            .then(response => response.json())
            .catch(err => console.log(err))
        //console.log(response)
        if (Array.isArray(response)) {
            setSurveyList(response)
            return
        } 
        // reset after timer
        setNotFound(true)
        timer.current = setTimeout(() => {
          setNotFound(false)
        }, 3000)
      }
    
    return (<>
        <div style={{fontSize: "30px", margin: "50px"}}>
            <form onSubmit={handleSubmit}>
                <div className={"surveyUrlsForm"}>
                    <label>Handle:</label>
                    <input
                        type="text"
                        value={handle}
                        onChange={(e) => setHandle(e.target.value)} />
                    <label>Passcode:</label>
                    <input
                        type="text"
                        value={passcode}
                        onChange={(e) => setPasscode(e.target.value)} />
                    <input className="submit" type="submit" value="Submit"></input>
                    <div style={{color: "red", textAlign: "center", margin: "10px"}} className="passwordFormResponseText">{notFound ? "Not Found" : ""}</div>
                </div>
            </form>
            <table style={{margin: "50px"}}>
                <tbody>
                    {Object.values(surveyList).map(val => 
                        (<tr key={val.label}>
                            <td key={val.label}>{val.label}</td>
                            <td key={val.url}><a href={val.url}>{val.url}</a></td>
                        </tr>)
                    )}
                </tbody>
            </table>

        </div>
    </>
    );
}

export default SurveyUrls;