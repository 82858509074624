import Draggable from "react-draggable"
import { setTimeAck } from "../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
// icons from https://www.freepik.com/icon/1st-prize_11224977#fromView=resource_detail&position=2
import firstPlace from '../../resources/prizes/1st-place.png'
import secondPlace from '../../resources/prizes/2nd-place.png'
import thirdPlace from '../../resources/prizes/3rd-place.png'
//import hardwareBadge from '../../resources/prizes/hardwarebadge.png'
// import challengeCoin from '../../resources/prizes/challengecoin.svg'
import hardwareBadge from '../../resources/prizes/SaikoCTF Badge Front.jpg'

import '../components.css'

function TimeInfo() {
    const dispatch = useDispatch()

    const uiFlagToExperience = {
        // ilab1: {
        //     prizes: [
        //         { text: '1st Place', text2: '$100', image: firstPlace }, 
        //         { text: '2nd Place', text2: '$50', image: secondPlace },
        //         { text: '3rd Place', text2: '$25', image: thirdPlace }, 
        //         { text: 'Everyone who completes SaikoCTF Challenge', text2: 'Hardware Badge', image: hardwareBadge }, 
        //         // { text: 'Completing SaikoCTF Registration', text2: 'Challenge Coin', image: challengeCoin }, 
        //       ],
        //       registration: "Registration takes about 50 minutes (5 minutes to review consenting information, " +
        //       "20 minutes for a skill screener, and 25 minutes for surveys.) " +
        //       "At the end, you will have the opportunity to schedule a timeslot for SaikoCTF.",
        // },
        // iconf1hitbsec: {
        //     prizes: [
        //         { text: '1st Place', text2: '$500', image: firstPlace }, 
        //         { text: '2nd Place', text2: '$300', image: secondPlace },
        //         { text: '3rd Place', text2: '$100', image: thirdPlace }, 
        //         { text: 'Everyone who completes SaikoCTF Challenge', text2: 'Hardware Badge', image: hardwareBadge }, 
        //         // { text: 'Completing SaikoCTF Registration', text2: 'Challenge Coin', image: challengeCoin }, 
        //       ],
        //     registration: "Registration takes about 30 minutes (5 minutes to review consenting information " +
        //             "and 25 minutes for surveys.) " +
        //             "At the end, you will have the opportunity to schedule a timeslot for SaikoCTF.",
        // },
        // ecsc: {
        //     prizes: [
        //         { text: '1st Place', text2: '400 USD', image: firstPlace }, 
        //         { text: '2nd Place', text2: '200 USD', image: secondPlace },
        //         { text: '3rd Place', text2: '100 USD', image: thirdPlace }, 
        //         { text: 'Everyone who completes SaikoCTF Challenge', text2: 'Hardware Badge', image: hardwareBadge }, 
        //         // { text: 'Completing SaikoCTF Registration', text2: 'Challenge Coin', image: challengeCoin }, 
        //       ],
        //     registration: "",
        // },
        ekoparty: {
            registration: "",
            prizes: [
                { text: '1st Place', text2: '400 USD', image: firstPlace }, 
                { text: '2nd Place', text2: '200 USD', image: secondPlace },
                { text: '3rd Place', text2: '100 USD', image: thirdPlace }, 
                // { text: 'Everyone who completes SaikoCTF', text2: 'Hardware Badge', image: hardwareBadge }, 
              ],
            consolationPrize: { text: 'Everyone who completes SaikoCTF', text2: 'Hardware Badge', image: hardwareBadge }, 
        }
    }

    const eventKey = useSelector((state => state.auth.uiFlag))
    const experience = uiFlagToExperience[eventKey]

    function genPrizeBullet (prize) {
        return <div className="tab-text prize-bullet" key={prize.text} style={{marginLeft: "20px", display: "flex"}}>
            <img style={{marginRight: "10px", height: "60px", width: "60px", minWidth: "60px"}} alt=""  src={prize.image}></img>
            <div style={{padding: "15px 0"}}>{prize.text2} - {prize.text}                        </div> 
        </div>
    }

    return (<Draggable handle=".tabHeadingBar"
        positionOffset={{ x: '-50%', y: '-50%' }}
        bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}>
        <div style={{height: "60vh"}} className="no-cursor tabDefaults windows95Tab timeInfo">
            <div className="tabHeadingBar"><div className="tabHeading">Welcome</div></div>
            <div className="tabContents">
                <div style={{margin: "20px"}}>
                    <div className="smaller-tab-text">
                        <strong>
                        Para participar en SaikoCTF, debes
                        <br/>
                        <div style={{margin: "30px"}}>
                        1. saber leer y conversar en Inglés.  <br/>
                        2. haber competido en al menos 8 CTFs o más.  <br/>
                        3. ser mayor de 18 años.<br/>  
                        </div>
                        </strong>
                        SaikoCTF is a remote service exploitation, web app and password cracking challenge.  
                        You must be 18 or older to play (U.S. laws)! Please don’t continue if you are under 18.  
                        <br/><br/>
                        <i>You must understand English instructions and be an experienced CTF player.</i>
                    </div>
                    <div style={{marginTop: "30px"}}>{experience.registration}</div>
                    <div className="tab-text" style={{marginTop: "20px"}}>Prizes available:</div>
                    <div className="tab-text prize-bullet" >Up to 5 flags from main CTF (sponsored by SaikoCTF)</div>
                    <div className="tab-text prize-bullet" >
                        Top three winners of SaikoCTF:
                        <br/>
                        <div style={{marginLeft: "20px"}}>
                            {experience.prizes.map((val) => (genPrizeBullet(val)))}
                        </div>
                    </div>
                    {genPrizeBullet(experience.consolationPrize)}
                </div>
                <div className="centerText">
                    <button onClick={() => dispatch(setTimeAck())} className="windows95Tab tabDefaultButton leftButton">Ok</button>
                </div>
            </div>
        </div>
    </Draggable>);
}

export default TimeInfo;