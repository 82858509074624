import Draggable from "react-draggable"
import { useDispatch } from "react-redux";
import { setHasProjectInfoAck } from "../../redux/features/authSlice";
import eeg from '../../resources/projectinfo/eeg.png'
import '../components.css'

function ProjectInfo() {
    const dispatch = useDispatch()
    return (<Draggable handle=".tabHeadingBar"
        positionOffset={{ x: '-50%', y: '-50%' }}
        bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}>
        <div style={{height: "60vh"}} className="no-cursor tabDefaults windows95Tab infoTab">
            <div className="tabHeadingBar"><div className="tabHeading">Research Project</div></div>
            <div className="tabContents">
                <div className="project-text" style={{margin: "20px"}}>
                    Do you like CTFs? Are you interested in participating in cutting-edge research? 
                    Then SaikoCTF is for you.
                    <br/><br/>
                    <div>
                        <img style={{height: "200px", width: "200px", float: "right"}} alt="" src={eeg}></img>                    
                        We are collecting data to research cyber defenses. 
                        <br/>
                        <br/>
                        Solve six CTF challenges while wearing interesting (but optional) sensor gear: EEG headset, 
                        heart-rate belt and galvanic skin response sensor. 
                        To participate please review the consent form and agree.
                    </div>
                </div>
                <div className="centerText">
                    <button onClick={() => dispatch(setHasProjectInfoAck())} className="windows95Tab tabDefaultButton leftButton">Ok</button>
                </div>
            </div>

        </div>
    </Draggable>);
}

export default ProjectInfo;