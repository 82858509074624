import './InfoSite.css'
import ReactiveText from './ReactiveText';
import { useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Loader } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

function InfoSite({ link }) {

    const [isLoading, setIsLoading] = useState(true)
    const [object, setObject] = useState(null);
        const [isInteracting, setIsInteracting] = useState(false)

    useEffect(() => {
        const loader = new OBJLoader();
        console.log("starting loading...")
        loader.load('/eeg-headset-centered.obj', (obj) => {
            obj.scale.set(0.17, 0.17, 0.17)
            setObject(obj);
            setIsLoading(false); // Set loading to false after loading
        }, undefined, (error) => {
            console.error('Error loading the object:', error)
            setIsLoading(false); // Handle error case
        });
    }, []);

    const Teapot = () => {
        
        useFrame(({ clock }) => {
            if (object && !isInteracting) {
                object.rotation.y += .005; // Adjust the rotation speed as needed
            }
        });

        const handleMouseDown = () => setIsInteracting(true);
        const handleMouseUp = () => setIsInteracting(false);
        const handleTouchStart = () => setIsInteracting(true);
        const handleTouchEnd = () => setIsInteracting(false);

        return (
            <mesh
                onPointerDown={handleMouseDown}
                onPointerUp={handleMouseUp}
                onPointerCancel={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                {object && <primitive object={object} />}
            </mesh>
        );
    };



    return (
        <div className="page">
            <div className="page1 background">
                <div className="hamburger"></div>
                <div className="padding"></div>
                <div><ReactiveText extraclasses="element-1 title italics no-highlight" text="SAIKOCTF" /></div>
                <div onClick={() => window.location.href = link} className="element-2 sign-up no-highlight">SIGN UP</div>

            </div>
            <div className="page2 background">
                <div className="page-2-flex">
                    <div><ReactiveText extraclasses="title-2 italics no-highlight left" text="Research&#10;Project" /></div>
                    <div className="wide">
                        <div className="right obj">
                            { !isLoading ?
                            <Canvas>
                                <directionalLight
                                    position={[10, 10, 10]}
                                    intensity={1.6}
                                    castShadow
                                    shadow-mapSize-width={1024}
                                    shadow-mapSize-height={1024}
                                    shadow-camera-near={1}
                                    shadow-camera-far={100}
                                    shadow-camera-left={-10}
                                    shadow-camera-right={10}
                                    shadow-camera-top={10}
                                    shadow-camera-bottom={-10}
                                />
                                <OrbitControls enableZoom={false} />
                                <ambientLight intensity={1.8} />
                                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                                <Teapot />
                                <Loader />
                            </Canvas> : <></>}
                        </div>
                    </div>
                </div>
                <div className="text-container">
                    <div className="readable-subtext sixty no-highlight">Do you like CTFs?
                        Are you interested in participating in cutting-edge research? Then SaikoCTF is for you.
                        Solve six CTF challenges while wearing a cool headset with 21 EEG sensors (brainwaves),
                        heart rate and galvanic skin response sensors. We are a group of researchers collecting data to research cyber defenses.
                    </div>
                </div>
            </div>
            <div className="page3 background">
                <div className="page-3-flex">
                    <div className="page-3-left">
                        <ReactiveText child={<img className="badge" src="/saiko-badge.png" alt="our cool badge" />} />
                    </div>
                    <div className="page-3-right"></div>
                    <div className="right-title">
                        <div><ReactiveText extraclasses="page-2-right title-2 italics no-highlight right-title" text="Amazing&#10;Prizes" /></div>
                    </div>


                </div>
                <div className="text-container">
                    <div className='right-title'>
                        <div className="right-title readable-subtext-2 sixty no-highlight">We have awesome prizes.
                            Everyone who participates gets a custom, limited-edition PCB badge, loaded with challenges and Pi Pico W that you can re-use.
                            Cash prices for top three winners:
                            <div className="readable-subtext-2 sixty no-highlight">1st: $400</div>
                            <div className="readable-subtext-2 sixty no-highlight">2nd: $200</div>
                            <div className="readable-subtext-2 sixty no-highlight">3rd: $100</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page4 background">
                <div className="page-2-flex">
                    <div className="right-title">
                        <div onClick={() => window.location.href = link}><ReactiveText extraclasses="element-1 title italics no-highlight push-down" text="SIGN UP!!!" /></div>
                    </div>
                </div>
            </div>
        </div>);
}

export default InfoSite;