import React from "react"
import './components.css'
import DesktopIcons from "./DesktopIcons.jsx";
import Draggable from "react-draggable";
import TaskBar from './TaskBar.jsx'
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Countdown from 'react-countdown';
import pwnCollege1 from '../resources/pwn.college1.png'

const defaultPollIntervalMs = 60 * 1000
const timerBufferMs = 1 * 1000

function Survey1() {
    const location = useLocation()
    //console.log("location state", location, location?.state)
    const username = location?.state?.username
    const passcode = location?.state?.passcode
    const eventKey = location?.state?.eventKey
    const token = location?.state?.token
    const redcapSurveyUrl = location?.state?.rcu
    const pwnCollegeSurveyUrl = 'https://pwn.college/login?next=/skill-screener~e6c3a6ef?'
    const [curStatus, setCurStatus] = useState({});
    const schedulerUrl =  `${window.location.origin}/scheduler/${eventKey}/?token=${token}`

    useEffect(() => { 
       async function verifySkillScreener () {
        fetch(`/verifySkillScreener?name=${username}&code=${passcode}&eventKey=${eventKey}`)
            .then(response => response.json())
            .then(status => {
                //console.log("verifySkillScreener result", status)
                setCurStatus(status)
                if (!status?.finished) {
                  const t = Math.min((status?.remainingMs || 0) + timerBufferMs, defaultPollIntervalMs)
                   // console.log("verifySkillScreener setTimeout1", t)
                  setTimeout(verifySkillScreener, t)
                }
            })
            .catch (err => {
                console.error("verifySkillScreener api failed", err)
                setTimeout(verifySkillScreener, defaultPollIntervalMs)
            })
      } 
      verifySkillScreener()
    }, [eventKey, passcode, redcapSurveyUrl, username, setCurStatus])    

    function countdownRenderer ({hours, minutes, seconds, completed}) {
      // if (completed) {
      //       return <></> 
      // } else {
          // Render a countdown
          return <>
            <div style={{marginTop: "30px"}}>As soon as you have solved the skill screener or after {`${curStatus?.allottedMinutes}`} minutes, return to this page to continue scheduling your slot. Do not close this tab.</div>
            <div className="wrapped" style={{marginTop: "30px"}}>Your handle: <strong>{username}</strong></div>
            <div className="wrapped" style={{marginTop: "30px"}}>Your password: <strong>{passcode}</strong></div>
            <div style={{marginTop: "30px"}}><span>Time remaining: <strong>{String(minutes).padStart(2,'0')}:{String(seconds).padStart(2, '0')}</strong></span></div>
            <div style={{marginTop: "30px"}} className="largeTabText centerText"><a href={pwnCollegeSurveyUrl} target="_blank" rel="noreferrer">GO TO SKILL SCREENER</a></div>
          </>
        // }
      };


    function renderScheduler() {
      return <>
        <div>
          {curStatus?.skipped ? 
            "Thank you for registering for SaikoCTF!": 
            "Congratulations, you qualified for SaikoCTF!"
          }
        </div>
        <div className="wrapped" style={{marginTop: "30px"}}>Your handle: <strong>{username}</strong></div>
        <div className="wrapped" style={{marginTop: "30px"}}>Your password: <strong>{passcode}</strong></div>
        <br/>
        Please come to the registration desk to schedule a slot. Slots are going fast, so please come as soon as possible.
        <br/><br/>
        Bring a screenshot of your handle and passcode to our booth.

        {/* <br/>
        <div className="wrapped"><strong>Take a screenshot of your handle and password!</strong></div>
        <br/>
        <div className="wrapped">Click button below to schedule now, or come with your handle and password to our booth.</div>
        <br/>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><button onClick={() => window.location.href = schedulerUrl} className="windows95Tab tabDefaultButton scheduler-button scheduler-text">&nbsp;Schedule now!&nbsp;</button></div> */}
      </>        
    }

    function renderBody () {
      if (curStatus?.skipped) {
        return renderScheduler()
      }
      else if (curStatus?.finished) {
        if (curStatus?.passing) return renderScheduler()
        else return <div>Thank you for your time, but you did not pass the skill screener in the time allotted. Goodbye.</div>
      }
      else if (!('remainingMs' in curStatus) || !('deadline' in curStatus)) {
        return <div>Loading...</div>
      } 

      return <>
        <div>
          To ensure that you will enjoy SaikoCTF, please click on the skill screener link below.
          Use your handle and password to log into the skill screener. 
          This is best done on a laptop or desktop.
          <br/><br/>
          After logging in, select <strong>Screening Module</strong>.
          To start each challenge, click on  <strong>VSCode Workspace</strong> as shown below.
        </div>
        <img style={{marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block"}} alt="pwncollege1"  src={pwnCollege1}></img>

        <Countdown date={Date.parse(curStatus?.deadline) + timerBufferMs} renderer={countdownRenderer}/>
      </>

    }
  

    return (<>
        <div className="container">
            <DesktopIcons/>
            <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className={"cursor tabDefaults windows95Tab skillscreenertab smaller-tab-text"} >
                <div className="tabHeadingBar"><div className="tabHeading">Skill Screener</div></div>
                <div className="tabContents">
                  <div style={{margin: "20px"}}>
                    {renderBody()}
                  </div>
                {/* <div className="centerText"><button className="tabDefaultButton" onClick={() => dispatch(setSurvey1Complete())}>continue (temp)</button></div> */}
                </div>
            </div>
            </Draggable>
            
        </div>
        <TaskBar />
    </>
    );
}

export default Survey1;