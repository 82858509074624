import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import '../components.css'

// const Mailto = ({ email, subject = '', body = '', children }) => {
//     let params = subject || body ? '?' : '';
//     if (subject) params += `subject=${encodeURIComponent(subject)}`;
//     if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
//     return <a href={`mailto:${email}${params}`}>{children}</a>;
// };

function Scheduler() {
    // const search = window.location.search;
    // const searchParams = new URLSearchParams(search);
    // const username = searchParams.get("handle")
    // const uiFlag = searchParams.get("uiFlag")
    // const passcode = searchParams.get("code")
    const uiFlag = useSelector((state => state.auth.uiFlag))
    const username = useSelector((state => state.auth.username))
    const passcode = useSelector((state => state.auth.passcode))
    const token = useSelector((state => state.auth.token))

    const schedulerUrl =  `${window.location.origin}/scheduler/${uiFlag}/?token=${token}`

    // const uiFlagToExperience = {
    //     // ilab1: {
    //     //     scheduleStr: "SaikoCTF will take place from 29 July through 16 August 2024 from 8:30 AM- 6:00 PM. Starting times are every 30 minutes and up to 6 people can start at the same time.",
    //     //     inpersonLength: "up to four (4) hours",
    //     //     scheduleStr2: <>There are two ways to schedule a slot.  
    //     //                   You can come to the reception desk at IHMC (40 South Alcaniz St. Pensacola, FL 32502) and put your handle in the signup sheet, 
    //     //                   or you can schedule your slot by sending email to&nbsp; 
    //     //                   <Mailto email="events+ilab@saikoctf.org" subject={`SaikoCTF scheduling for ${username}`} >events+ilab@saikoctf.org</Mailto>. 
    //     //                   Please put your handle into the email.</>,
    //     // },
    //     // iconf1hitbsec: {
    //     //     scheduleStr: "SaikoCTF will take place from August 29 to August 30, 2024 from 8:30AM-6:00PM. Starting times are every 30 minutes and up to 8 people can start at the same time.",
    //     //     inpersonLength: "1 hour and 45 minutes (w/o physio sensors), up to two and a half (2.5) hours (with physio sensors)",
    //     //     scheduleStr2: <>To schedule a slot, please send an email to&nbsp; 
    //     //                   <Mailto email="events+hitb@saikoctf.org" subject={`SaikoCTF scheduling for ${username}`} >events+hitb@saikoctf.org</Mailto>. 
    //     //                   Please put your handle into the email. Also please provide a ranked list with a couple of options (day and time) when you would like to start your SaikoCTF experience. 
    //     //                   We will email you your scheduled time.</>,
    //     // },
    //     ecsc: {
    //         scheduleStr: "SaikoCTF takes place from October 8, 2024 to Oct 10, 2024, each day from 10:00 to 18:00.",
    //         inpersonLength: "1 hour and 45 minutes (w/o physio sensors), up to two and a half (2.5) hours (with physio sensors)",
    //         scheduleStr2: <a href={schedulerUrl} style={{fontSize: "50px"}}>Click to schedule your slot!</a>,
    //     },
    //     ekoparty: {
    //         scheduleStr: "SaikoCTF takes place from Nov 13, 2024, Nov 15, 2024,  ~~~ each day from 10:00 to 18:00 ~~~, at our booth at EkoParty, Level 2, near the Main CTF and Villages Arena",
    //         inpersonLength: "1 hour and 45 minutes (w/o physio sensors), up to two and a half (2.5) hours (with physio sensors)",
    //         scheduleStr2: <a href={schedulerUrl} style={{fontSize: "50px"}}>Click to schedule your slot!</a>,
    //     }
    // }
    //const experience = uiFlagToExperience[uiFlag]

    return (
        <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className={"cursor tabDefaults windows95Tab schedulertab"} >
                <div className="tabHeadingBar"><div className="tabHeading">Scheduling</div></div>
                <div className="tabContents">
                    <div style={{ margin: "20px" }}>
                        <div className="tab-text wrapped scheduler-text-large">Your handle: <strong>{username}</strong></div>
                        <div className="tab-text wrapped scheduler-text-large">Your password: <strong>{passcode}</strong></div>
                        <div className="scheduler-text"><strong>Take a screenshot of your handle and password!</strong></div>
                        <div className="scheduler-text">Click button below to schedule now, or come with your handle and password to our booth.</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><button onClick={() => window.location.href = schedulerUrl} className="windows95Tab tabDefaultButton scheduler-button scheduler-text">&nbsp;Schedule now!&nbsp;</button></div>
                    </div>
                </div>
            </div>
        </Draggable>);
}

export default Scheduler;