import { useSelector, useDispatch } from "react-redux";
import { useState, useRef } from "react";
import Draggable from "react-draggable";
import { useNavigate } from "react-router-dom";
// import experienceOneForm1Web from '../../resources/experienceone/in-house-consent-1of2.html'
// import experienceOneForm1Download from '../../resources/experienceone/in-house-consent-1of2.pdf'
// import experienceOneForm2Web from '../../resources/experienceone/in-house-consent-2of2.html'
// import experienceOneForm2Download from '../../resources/experienceone/in-house-consent-2of2.pdf'
// import experienceTwoForm1Web from '../../resources/experiencetwo/SaikoCTF Conference Consent form HITBSecConf 1of2-formatted.html'
// import experienceTwoForm1Download from '../../resources/experiencetwo/SaikoCTF Conference Consent form HITBSecConf 1of2-formatted.pdf'
// import experienceTwoForm2Web from '../../resources/experiencetwo/SaikoCTF Conference Consent form HITBSecConf1 2of2-formatted.html'
// import experienceTwoForm2Download from '../../resources/experiencetwo/SaikoCTF Conference Consent form HITBSecConf1 2of2-formatted.pdf'
import experience03Form1Web from '../../resources/experience03/SaikoCTF-conference-consent-form-1-ECSC.html'
import experience03Form1Download from '../../resources/experience03/SaikoCTF-conference-consent-form-1-ECSC.pdf'
import experience03Form2Web from '../../resources/experience03/SaikoCTF-conference-consent-form-2-ECSC.html'
import experience03Form2Download from '../../resources/experience03/SaikoCTF-conference-consent-form-2-ECSC.pdf'
import ekopartyForm1Web from '../../resources/experiences/ekoparty/SaikoCTF-conference-consent-form-1-EkoParty.html'
import ekopartyForm1Download from '../../resources/experiences/ekoparty/SaikoCTF-conference-consent-form-1-EkoParty.pdf'
import ekopartyForm2Web from '../../resources/experiences/ekoparty/SaikoCTF-conference-consent-form-2-EkoParty.html'
import ekopartyForm2Download from '../../resources/experiences/ekoparty/SaikoCTF-conference-consent-form-2-EkoParty.pdf'




import '../components.css'
import { setHasConsent2 } from "../../redux/features/authSlice";

async function giveConsent(username, code, consent) {
    const response = await fetch("/consent?" + new URLSearchParams({
        name: username,
        code: code,
        consent: consent,
    }).toString()).then(response => response.json()).catch(err => console.log(err))
    return response && response?.succeeded
}

function PDFConsent() {
    const dispatch = useDispatch()

    // TODO consider moving experience map to common area
    const uiFlagToExperience = {
        // 2024.09.12 disabled old events to minimize download size
        // ilab1: {
        //     forms: [
        //         { web: experienceOneForm1Web, download: experienceOneForm1Download, buttons: ['Yes', 'No']},
        //         { web: experienceOneForm2Web, download: experienceOneForm2Download, buttons: ['I Wish To Participate', 'Decline']},
        //     ]
        // },
        // iconf1hitbsec: {
        //     forms: [
        //         { web: experienceTwoForm1Web, download: experienceTwoForm1Download, buttons: ['Yes', 'No']},
        //         { web: experienceTwoForm2Web, download: experienceTwoForm2Download, buttons: ['I Wish To Participate', 'Decline']},
        //     ]
        // },
        ecsc: {
            forms: [
                { web: experience03Form1Web, download: experience03Form1Download, buttons: ['Yes', 'No']},
                { web: experience03Form2Web, download: experience03Form2Download, buttons: ['I Wish To Participate', 'Decline']},
            ]
        },
        ekoparty: {
            forms: [
                { web: ekopartyForm1Web, download: ekopartyForm1Download, buttons: ['Yes', 'No']},
                { web: ekopartyForm2Web, download: ekopartyForm2Download, buttons: ['I Wish To Participate', 'Decline']},
            ]
        }
    }
    const eventKey = useSelector((state => state.auth.uiFlag))
    const username = useSelector((state => state.auth.username))
    const passcode = useSelector((state => state.auth.passcode))
    const token = useSelector((state => state.auth.token))
    const redcapSurveyUrl = useSelector((state) => state.auth.redcapSurveyUrl)
    const experience = uiFlagToExperience[eventKey]
    const [currentConsent, setCurrentConsent] = useState(0);
    const tabRef = useRef()

    const navigate = useNavigate();
    function navNext() {
        navigate( {
            pathname: '/survey1',
          }, {
            state: {
                username: username,
                passcode: passcode,
                eventKey: eventKey,
                rcu: redcapSurveyUrl,
                token: token,
            },
          }
        );
        // dispatch(setHasConsent2())
    }

    function navDecline() {
        navigate( {
            pathname: '/decline',
          }, {
            state: {
                username: username,
                passcode: passcode,
            }
        });
    }

    async function onConsentClick() {
        if (currentConsent >= experience.forms.length - 1) {
            if (await giveConsent(username, passcode, true)) navNext()
        } else {
            tabRef.current.scrollTo(0,0);
            setCurrentConsent(currentConsent + 1)
        }
    }    

    return (
        <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className={"cursor tabDefaults windows95Tab consentingTab"} >
                <div className="tabHeadingBar"><div className="tabHeading">Consent</div></div>
                <div ref={tabRef} className="tabContents">
                    {currentConsent < experience.forms.length ?
                        <div className="scrollable htmlFormContainer">
                            <div className="htmlForm" dangerouslySetInnerHTML={{ __html: experience.forms[currentConsent].web }} />
                        </div>
                        :
                        <></>}

                    <div className="centerText">
                        {currentConsent < experience.forms.length ?
                            <>
                                <div>
                                    <a style={{fontSize: "20pt", textDecoration: "underline", fontFamily: 'Courier New'}} target="_blank" rel="noopener noreferrer" href={experience.forms[currentConsent].download}>View PDF</a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <button className="windows95Tab tabDefaultButton leftButton" onClick={onConsentClick}>
                                        &nbsp;{experience.forms[currentConsent].buttons[0]}&nbsp;
                                    </button>
                                    <button onClick={navDecline} className="windows95Tab tabDefaultButton rightButton"> 
                                        &nbsp;{experience.forms[currentConsent].buttons[1]}&nbsp;
                                    </button>
                                </div>
                            </>
                            :
                            <></>
                            // <button className="tabDefaultButton magnetBottom" onClick={() => setCurrentConsent(currentConsent - 1)}>back</button>
                        }
                    </div>

                </div>
            </div>
        </Draggable>);
}

export default PDFConsent;